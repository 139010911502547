.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 14px;
  background-color: var(--background-color);
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  overflow: visible;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: flex-start;
}

.avatar {
  object-fit: cover;
  padding: 3px;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  border: 1.26px solid var(--secondary);
  background: rgba(var(--text-color-rgb), 12%);
}

.textArea {
  border: none;
  padding: .5rem;
  width: 100%;
  border-radius: 14px;
  background-color: transparent;
  resize: none;
  font-family: "Manrope";
  font-size: 1rem;
  font-weight: 600;
  flex: 1 1;
  border-radius: 14px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  background-color: var(--background-secondary-color);
  color: var(--text-color)
}

.textArea:focus {
  outline: none;
}

.textArea::placeholder {
  color: rgba(var(--text-color-rgb), 12%)
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}


.contentTypes {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
}

.blueButton {
  background-color: var(--tertiary);
}

.dropzone {
  background-color: rgba(var(--tertiary-rgb), 8%);
  border: 2px dashed #4CB4FF32;
  border-radius: 12px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
  margin-top: 1rem;
}

.dropzoneLabel {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--text-color-rgb), 72%);
  text-align: center;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 700;
  cursor: pointer;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.file {
  flex: 1 1;
  max-width: 377px;
}

.testContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: .5rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.addTest {
  width: 100%;
  min-height: 256px;
  border-radius: 12px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
}

.testsDialog {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tests {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}


.test {
  flex-basis: 33.33%;
  flex-grow: 1;
  min-width: 300px;
}

.exerciseContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.exerciseInputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.noItems {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 256px;
  height: 100%;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
}

.materialContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-bottom: .5rem;
}

@media (hover: hover) {


  .blueButton:hover {
    color: var(--tertiary);
    background-color: var(--background-color);
    box-shadow: inset 0px 0px 0px 2px var(--tertiary);
    transition: .2s ease-in-out all;
  }

  .blueButton:hover>svg>g>path {
    fill: var(--tertiary);
    stroke-width: 0;
  }

}

@media screen and (max-width: 768px) {
  .action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1024px) {
  .file {
    max-width: none;
  }
}

@media screen and (max-width: 540px) {
  .avatar{
    display: none;
  }

  .contentTypes {
    padding-left: 0 !important;
    justify-content: space-between;
  }

  .action {
    justify-content: center;
  }
}