.container {
    background-color: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    padding: .8rem 1rem;
    color: var(--text-color)
}

.container.tablet,
.container.mobile {
    display: flex;
    flex-direction: column;
}

.left {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-grow: 1;
    max-width: 40%;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.header .exerciseInfo {
    flex-grow: 1;
}

.header .thumbnail {
    min-width: 44px;
    width: 80px;
    height: 56px;
}

.mobile .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: .5rem;
}

.mobile .material {
    flex-grow: 1
}

.header .name {
    font-size: .875rem;
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.material {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.name {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.size {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 48%)
}

.icon>svg {
    width: 16px;
    height: 16px;
}

.time {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: rgba(var(--text-color-rgb), 32%);
    font-size: .875rem;
    font-weight: 700;
    white-space: nowrap;
    justify-content: flex-end;
}

.exerciseInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 0.5;
}

.exerciseInfo {
    display: flex;
    align-items: center;
    gap: .3rem;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.exerciseInfo>svg {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
}

.thumbnail {
    border-radius: 6px;
    height: 56px;
}


.mobile .action {
    justify-content: center;
}

.action {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
}

.download {
    border: 1.33px solid rgba(var(--primary-rgb), 32%);
    background-color: rgba(var(--primary-rgb), 4%);
    border-radius: 8px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    flex-grow: 1;
}

.picture {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    padding: 4px;
}

.username {
    font-weight: 700;
    font-size: 1rem;
    color: rgba(42, 42, 42, 80%);
    text-align: center;
}

.dialog {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .7rem;
    padding: 1rem;
    overflow-y: scroll;
}

.textAreaContainer {
    display: flex;
    flex-direction: row;
}

.textArea {
    border: none;
    padding: .5rem 0;
    width: 100%;
    border-radius: 14px;
    background-color: transparent;
    resize: none;
    font-family: "Manrope";
    font-size: 1rem;
    font-weight: 600;
    flex: 1 1;
    background-color: transparent;
    color: var(--text-color)
}

.textArea:focus {
    outline: none;
}

.textArea::placeholder {
    color: rgba(var(--text-color-rgb), 12%)
}

.dropzoneContainer {
    display: flex;
    flex-direction: column;
}

.dropzone {
    background-color: rgba(var(--tertiary-rgb), 8%);
    border: 2px dashed #4CB4FF32;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    flex: 1;
    margin-top: 1rem;
}

.dropzoneLabel {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 72%);
    text-align: center;
}

.filesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
}

.files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

.file {
    width: 100%;
}

.subtitle {
    display: flex;
    align-items: center;
    gap: .3rem;
    font-weight: 700;
}
